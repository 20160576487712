import {mapState} from 'vuex';

export const common = {
	
	computed:{
		...mapState({
			test:state => state.test.test
		}),
		getSelectKey(){
			return this.$route.name;
		}
	},
	methods:{
		refreshPage(page){ // 比如弹出层操作后要跳转到某个页面就这样执行
			this.$router.push({path:'/refresh',query:{path:page}});
		}
	}
}